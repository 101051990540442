Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.ConnectAccount = "Connected accounts";
exports.instagram = "Instagram"
exports.facebook = "Facebook";
exports.twitter = "X";
exports.linkedIn = "LinkedIn";
exports.connect = "Connect";
exports.disconnect = "Disconnect";
exports.cancel = "Cancel";
exports.accountTH = "Account";
exports.realTH = "Real Time Analytics";
exports.userTH = "User Engagement Tools";
exports.contentTH = "Content Creation ";
exports.disConnectHeading = "Disconnect account?";
exports.disConnectContent = "Are you sure you want to disconnect your account?"
exports.active = "Active";
exports.paused = "Paused";
// Customizable Area End