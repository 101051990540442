import React from 'react';
// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  TextField,
} from '@mui/material';
import { searchIcon, starIcon, productIcon, campaignIcon, subcriberIcon, blogIcon } from './assets';
import GenerateContentController, { Props, configJSON } from './GenerateContentController';
// Customizable Area End

export default class GenerateContent extends GenerateContentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    renderGenerateContent = () => {
      return(<>
        <MainContainer>
         <GenerateBox>
           <HeadingContent data-testId="generateLabelTestID">{configJSON.letsCreate}</HeadingContent>
           <InputFieldBox>
           <img src={searchIcon} className="search_img" />
              <TextField
                data-testId="generatedInputTestID"
                value={this.state.contentText}
                onChange={this.handleGenerateContent}
                className='textField'
                placeholder='What shall we create today?'
              />
           <Box onClick={this.handleChatPage} data-testId="generateContentTestID" className='textFieldBtn'>{configJSON.generate}<img src={starIcon} className="star_img" /></Box>
           </InputFieldBox>
           <ExampleContent>{configJSON.exampleContent}</ExampleContent>
           <ContentHeading>{configJSON.usefulContent}</ContentHeading>
            <StartPointContainer>
              <StartPointBox>
                <img src={productIcon} className="start_img" />
                <Typography className="start_point_content">{configJSON.productContent}</Typography>
              </StartPointBox>
              <StartPointBox>
                <img src={campaignIcon} className="start_img" />
                <Typography className="start_point_content">{configJSON.campaignContent}</Typography>
              </StartPointBox>
              <StartPointBox>
                <img src={subcriberIcon} className="start_img" />
                <Typography className="start_point_content">{configJSON.subcriberContent}</Typography>
              </StartPointBox>
              <StartPointBox>
                <img src={blogIcon} className="start_img" />
                <Typography className="start_point_content">{configJSON.blogContent}</Typography>
              </StartPointBox>
            </StartPointContainer> 
            <GuideContainer>
            <GuideContent>
             {configJSON.GuideContent}
             <span className="guided_content">{configJSON.GuidedContent}</span>
            </GuideContent>
            <GuideButton onClick={this.startGuided}>
              {configJSON.GuideBtnContent}
            </GuideButton>
            </GuideContainer>        
          </GenerateBox>
        </MainContainer>
      </>)
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <>
            {this.renderGenerateContent()}
        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  height: "100%",
  backgroundColor: "#FFFDD8",
  padding : "100px",
  overflow: "scroll",
  scrollbarWidth: "none",
  "@media (max-width: 1000px)": {
    padding : "50px 20px"
  },
  "@media (max-width: 600px)": {
    padding : "50px 20px"
  },
})

const GenerateBox = styled(Box)({
  width: "100%"
})

const HeadingContent = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "32px",
  color: "#4D4D4D"
 })

const InputFieldBox = styled(Box)({
  width: "100%",
  height: "65px",
  marginTop: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "100px",
  border: "1px solid #DDDDDDDD",
  backgroundColor: "#FFFFFF",
  overflow: "hidden",
  scrollbarWidth: "none",
  cursor: "pointer",
  "& .textField":{
    width: "100%",
    backgroundColor: "#FFFFFF",
    border:"none !important",
  "& .css-igs3ac":{
    border:"none !important",
  },
  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline":{
    borderStyle: "unset"
  }
  },
  "& .search_img": {
    height: "20px",
    marginLeft: "15px"
  },
  "& .textFieldBtn": {
    backgroundColor: "#000000",
    width: "200px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    color:"#FFFFFF",
    justifyContent: "center",
    gap: "10px",
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "18px",  
    cursor : "pointer"
  },
  "& .star_img":{
    height: "20px"
  },  
 })

 const ExampleContent = styled(Box)({
  padding: "0 50px",
  marginTop: "35px",
  fontFamily: "Open Sans",
  fontWeight: 300,
  fontSize: "16px",
  fontStyle:"italic"
})

const ContentHeading = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "18px",
  color: "#4D4D4D",
  marginTop: "80px"
 })

 const StartPointContainer = styled(Box)({
   display: "flex",
   marginTop: "30px",
   justifyContent: "space-between",
   flexWrap: "wrap",
   gap: "20px"
 })

 const StartPointBox = styled(Box)({
   flex: "1 0 150px",
   border: "0.5px solid #000000",
   borderRadius: "20px",
   padding: "16px",
   textAlign: "center",
   backgroundColor: "#FFFEE1",
   cursor: "pointer",
   "& .start_img": {
     height: "24px",
     color: "#3D3D3D"
   },
   "& .start_point_content": {
     marginTop: "15px",
     fontFamily: "Open Sans",
     fontWeight: 400,
     fontSize: "16px",
     color: "#4D4D4D",
   }
 })

const GuideContainer = styled(Box)({
   display: "flex",
   justifyContent: "space-between",
   alignItems:"center",
   flexWrap: "wrap",
   marginTop: "45px",
   gap: "20px"
})

const GuideContent = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "16px",
  color: "#4D4D4D",
  "& .guided_content": {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px",
    color: "#4D4D4D",
    marginLeft: "5px"
  }
})

const GuideButton = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "16px",
  color: "#FFFFFF",
  backgroundColor: "#000000",
  padding: "10px 16px",
  borderRadius: "8px",
  cursor: "pointer"
})
// Customizable Area End 