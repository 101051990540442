export const facebookIcon = require('../assets/facebook.svg').default
export const inboxIcon = require('../assets/inbox.svg').default
export const instagramIcon = require('../assets/instagram.svg').default
export const noteICon = require('../assets/noteIcon.svg').default
export const renameIcon = require('../assets/renameIcon.svg').default
export const backArrow = require('../assets/backArrow.svg').default
export const editIcon = require('../assets/editPan.svg').default
export const facebookColor = require("../assets/facebookColor.svg").default;
export const instagramColor = require("../assets/instagramColor.svg").default;
export const fileIcon = require("../assets/file.svg").default;
export const threeDots = require("../assets/threeDots.svg").default;
export const heartFill = require("../assets/heartFill.svg").default;
export const messageIcon = require("../assets/messageIcon.svg").default;
export const shareIcon = require("../assets/shareIcon.svg").default;
export const saveIcon = require("../assets/saveIcon.svg").default;
export const profileIcon = require("../assets/profileIcon.svg").default;
export const regenerateIcon = require("../assets/regenerateIcon.svg").default;
export const saveToLibIcon = require("../assets/saveToLibIcon.svg").default;
export const fullScreenIcon = require("../assets/fullScreenIcon.svg").default;
export const deleteIcon = require("../assets/deleteIcon.svg").default;
export const paintIcon = require("../assets/paintIcon.svg").default;
export const galleryIcon = require("../assets/galleryIcon.svg").default;
export const playIcon = require("../assets/playIcon.svg").default;
export const loadingIcon = require("../assets/loadingIcon.svg").default;
export const noImage = require("../assets/noImage.svg").default;