// Customizable Area Start
import React from "react";
import {
Box,
Typography,
Button,
TextField,
Backdrop,
Modal,
Paper
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {  facebooklogo, instagramlogo, twitterlogo, tiktoklogo, magnifylicon,bgwhitefacebooklogo,bgwhiteinstagramlogo,bgwhitetwitterlogo,bgwhitetiktoklogo,Loader,buttondesign} from "./assets";
import styled from '@emotion/styled';
const themes = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import LandingPageController, {
Props, configJSON
} from "./LandingPageController";
import EmailAccountRegistration from "../../email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import VideoBackgrounds from "../../videobackgrounds/src/VideoBackgrounds.web"
import ForgetPassword from "../../forgot-password/src/ForgetPassword.web";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import HeaderTopNavigation from "../../../components/src/HeaderTopNavigation"
import Footerbar from "../../../components/src/Footerbar"

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);

  }
backGround = () => {
return(
    <>
     <Box style={{  display: 'flex', justifyContent: 'space-between', width: '200px'}}  >
                <img src={this.state.isBackgroundVideoAvailable ?bgwhitetwitterlogo : twitterlogo} alt="twitterogo" style={{ alignItems: 'center' }} />
                <img src={this.state.isBackgroundVideoAvailable ?bgwhitefacebooklogo :facebooklogo} alt="faceboologo" style={{ alignItems: 'center' }} />
                <img src={this.state.isBackgroundVideoAvailable ?bgwhiteinstagramlogo:instagramlogo} alt="instagramlogo" style={{ alignItems: 'center' }} />
                <img src={this.state.isBackgroundVideoAvailable ?bgwhitetiktoklogo: tiktoklogo} alt="tiktoklogo" style={{ alignItems: 'center' }} />
              </Box>
    </>
  )

}
privacyPolicy = () => {
return(
    <>
      <Box>
        <Box style={{ display: 'flex' }}>
          <Typography style={{ marginRight: '20px', fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', marginBottom: '20px', color: this.state.isBackgroundVideoAvailable ? '#FFFFFF ' : '#000000' }}>
            Privacy policy
          </Typography>
          <Typography style={{ fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', marginBottom: '20px', color: this.state.isBackgroundVideoAvailable ? '#FFFFFF ' : '#000000' }}>
            Terms & Conditions
          </Typography>
        </Box>
      </Box>
    </>
  )
}


Gradienttext=()=>{
return(
<>
    <StyledTextGradient
      style={{
        background: this.state.isBackgroundVideoAvailable ? 'none' : 'linear-gradient(to right, #0066FF, #E8CC17)',
        WebkitBackgroundClip: this.state.isBackgroundVideoAvailable ? 'none' : 'text',
        WebkitTextFillColor: this.state.isBackgroundVideoAvailable ? 'white' : 'transparent',
      }}>Talk with JARVYS</StyledTextGradient>
  </>
)

}

renderLoginSignupPage = () =>{
  return(
    <ModalLandingContainer
    open={this.state.openLoginSignup}
    disablePortal
    disableEnforceFocus
    disableAutoFocus
    className="deleteModal"
  >
    <Paper className="JarvaysLandingPaper">
      <IconButton
        onClick={this.closeLoginSignup}
        className="closeJarvysLandingBtn"
      >
        <CloseIcon />
      </IconButton>
      <Typography className="jarvaysLandingMsgBox">
          {configJSON.jarvysHeadMsg}
      </Typography>
      <Typography className="jarvys_landing_platform_msg">{configJSON.platformMessgae}</Typography>
      <Box className="buttonJarvysLandingBox">
        <Button className="signupLandingBtn" onClick={this.handleOpenSignUpForm.bind(this)}>
          {configJSON.signupText}
        </Button>
        <Button className="loginLandingBtn" onClick={this.handleOpenLogInForm.bind(this)}>{configJSON.loginText}</Button>
      </Box>
    </Paper>
  </ModalLandingContainer>
  )
}


render() {
  return (
      <ThemeProvider theme={themes}>
        <VideoBackgrounds id="landingPageBackGround" navigation={this.props.navigation}>
       <MainContainer>
          <HeaderTopNavigation isActive={this.state.isBackgroundVideoAvailable} 
           handlelogin={this.handleOpenLogInForm.bind(this) }
            handlesignup={this.handleOpenSignUpForm.bind(this)} 
            handleGoogleLogin={this.handleGoogleRegister.bind(this)}
            data-testid="headercomponent" />
        <Box sx={outer}>
        <StyleLandingContainer> 
           

            <TalkJarvysBox>
            <Box>
              <StyledCenterText color={this.state.isBackgroundVideoAvailable?'#FFFFFF ':'#4D4D4D'}>
                Marketing Made Easy</StyledCenterText>
            </Box>
              {this.Gradienttext()}
              <StyleJarvysParentBoxInput >
                <StyleJarvysInput style={{border:this.state.isBackgroundVideoAvailable? '#FFFFFF' :'2px solid #DDDDDD'}} >
                  <img  src={magnifylicon} alt="magnicon" style={{marginLeft:'12px'}} />
                  <TextField
                    className="jarvys_textField"
                    placeholder="Talk with JARVYS"
                    data-testid={"messageInput"}
                    fullWidth
                    multiline
                    maxRows={1}
                    variant="standard"
                    sx={{
                      "& fieldset": { border: 'none' },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontFamily:'Open Sans',
                        fontSize:'18px',
                        fontWeight:400,
                        border: 'none',
                        paddingLeft: '10px',
                        color: this.state.isBackgroundVideoAvailable?'#000000':'#4D4D4D',
                      },
                    }}
                    value={this.state.userMessage}
                    onChange={this.handleUserMessageChange.bind(this)}
                  />
                </StyleJarvysInput>
                <StyleJarvysBoxButton 
                  data-test-id="goToHomeButton"
                  data-testid="askToJarvysButton"
                  style={{
                   background:this.state.isBackgroundVideoAvailable ? 'linear-gradient(to right, #0066FF, #E8CC17)':'#202020',
                   
                  }}
                  test-data={this.state.userMessageResponse}
                  onClick={this.askToJarvys.bind(this)}> 
                  Ask JARVYS<img 
    src={buttondesign}
    alt="Icon" 
    style={{ marginLeft: '8px' }} 
  />
                </StyleJarvysBoxButton>
              </StyleJarvysParentBoxInput>
            </TalkJarvysBox>
            
            <Backdrop className="backdrop_modal" open={this.state.isOpenSignUpForm} onClick={this.handleCloseSignUpForm.bind(this)} data-testid='signUpBackdrop'>
              {this.state.isOpenSignUpForm && <EmailAccountRegistration
                id="signUpForm"
                navigation={this.props.navigation}
                data-testid='signUpForm'
                />}
            </Backdrop>
            <Backdrop className="backdrop_modal" open={this.state.isOpenLogInForm} onClick={this.handleCloseLogInForm.bind(this)} data-testid='loginUpBackdrop' sx={{zIndex:1}}>
              {this.state.isOpenLogInForm && <EmailAccountLoginBlock
                id="logInForm"
                onClose={this.handleCloseLogInForm.bind(this)}
                navigation={this.props.navigation}
                data-testid='logInForm'
                goToRegistration={this.handleOpenRegistrationFromLogIn.bind(this)}
                goToForgetPassword={this.handleOpenForgetPassword.bind(this)}
                />}
            </Backdrop>
            <ForgetPassword
              open={this.state.isOpenForgetPasswordForm}
              navigation={this.props.navigation}
              data-testid='forgetPassword'
              onClose={this.handleCloseForgetPasswordForm.bind(this)}
              goToLoginForm={this.handleOpenLogInForm.bind(this)}
            />
            </StyleLandingContainer>    
               </Box>
               <Modal data-testid="jarvysmodel"
                            open={this.state.jarvysmodelopenlanding}
                            aria-labelledby="email-verified-title"
                            aria-describedby="email-verified-description"
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <Box style={{
                                display: "flex",
                                justifyContent: 'center',
                                alignItems: 'center',
                                maxHeight: 'fit-content',
                                width: "fit-content",
                                margin: "10px",
                                position: "relative",
                            }}>

                                <StyledModelContainer>
                                    <StyledModelInnerContainer>
                                        <Box display={'flex'} justifyContent={'end'} >
                                            <IconButton onClick={this.handleclosejarvysmodel.bind(this)} data-testid="closejarvysmodelbutton">
                                                <CloseIcon style={{ color: '#0F172A' }} />
                                            </IconButton>

                                        </Box>
                                        
                                        <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap={'2vw'}>

                                                              <StyledModelTextGradient
                            style={{

                              background: 'linear-gradient(to right, #0066FF, #E8CC17)',
                              WebkitBackgroundClip:'text',
                              WebkitTextFillColor: 'transparent',
                            }}>JARVYS AI</StyledModelTextGradient>
                                        
                                        <Box
                                            sx={{
                                              width: '51px', 
                                              height: '50px',
                                              animation: 'rotate 2s linear infinite', 
                                            }}
                                          >
                                             <img   
                                             src={Loader}
                                            alt="Loading..."
                                            style={{
                                              width: '51px', 
                                              height: '50px',
                                              animation: 'rotate 2s linear infinite',                                              
                                            }}                                            
                                             />
                                          </Box>
                                          <ModelBottomTypo>JARVYS processes your question...</ModelBottomTypo>
                                      </Box>

                                    </StyledModelInnerContainer>
                                </StyledModelContainer>
                            </Box>
                        </Modal>
            <Footerbar isActive={this.state.isBackgroundVideoAvailable}/>
         {this.renderLoginSignupPage()}
       </MainContainer>
        </VideoBackgrounds>       
      </ThemeProvider>
    );
  }
}

const MainContainer = styled(Box)({
   width: "100%",
   height: "100vh",
   display: "flex",
   flexDirection: "column",
   justifyContent: "space-between",
})

const StyledModelContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#FFFFFF",
  maxHeight: '85vh',
  width: "40vw",
  padding: "40px",
  overflowY: "auto",
  borderRadius: '12px',
  '@media (max-height: 615px)': {
      maxHeight: '82vh',
  },
  '@media (max-height: 530px)': {
      maxHeight: '80vh',
  },
  '@media (max-height: 430px)': {
      maxHeight: '76vh',
  },
  '@media (max-height: 350px)': {
      maxHeight: '71vh',
  },
  '@media (max-height: 290px)': {
      maxHeight: '65vh',
  },
  '@media (max-height: 250px)': {
      maxHeight: '50vh',
  },
  '@media (max-height: 200px)': {
      maxHeight: '40vh',
  },
  '@media (max-height: 100px)': {
      maxHeight: '25vh',
  },
  "::-webkit-scrollbar": {
      display: 'none',
  },
  "-ms-overflow-style": 'none',
  "scrollbar-width": 'none',
  
});

const StyledModelInnerContainer = styled(Box)({
  width: '100%',
  height: "100%",
  '@media (max-width: 858px)': {
      flexDirection: 'column-reverse',
      justifyContent: "start",
      gap: "0px",
  },
});

const StyledModelTextGradient=styled(Typography)({
  fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '64px',
  
  '@media (max-width: 858px)': {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    fontSize: '5vw',


  },'@media (max-width: 768px)': {
    fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '5vw',

  },'@media (max-width: 626px)': {
    fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '5vw',

  },
  '@media (max-width: 468px)': {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    fontSize: '5vw',
  
  },'@media (max-width: 386px)': {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    fontSize: '5vw',
  
  },
})

const ModelBottomTypo=styled(Typography)({
  
  fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '18px',
  color:'#515151',
  
  '@media (max-width: 858px)': {
    fontWeight: 600, color:'#515151',
    fontFamily: 'Open Sans',
    fontSize: '2vw',


  },'@media (max-width: 768px)': {
    fontWeight: 600,
  fontFamily: 'Open Sans', color:'#515151',
  fontSize: '2vw',

  },'@media (max-width: 626px)': {
    fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '2vw', color:'#515151',

  },
  '@media (max-width: 468px)': {
    fontWeight: 600,
    color:'#515151',
    fontFamily: 'Open Sans',
    fontSize: '2vw',
  
  },'@media (max-width: 386px)': {
    fontWeight: 600,
    color:'#515151',
    fontFamily: 'Open Sans',
    fontSize: '2vw',
  
  },
})

const outer ={

  maxHeight:'fit-content',
  width:"fit-content",

  position:"relative",
  display:'flex',
justifyContent:'center',
alignItems:'center',
}
const StyleLandingContainer = styled(Box)({
  width:'100vw',
  "& .backdrop_modal": {
    zIndex: 1500
  }
});


const StyledCenterText=styled(Typography)({
  
  textAlign: 'center',
  fontSize: '2.5vw',
    fontWeight: 500,
     fontFamily: 'Open Sans',

  '@media (max-width: 1018px)': {
    textAlign: 'center',
    fontSize: '2.5vw',
     fontWeight: 500,
     fontFamily: 'Open Sans',
  },'@media (max-width: 1034px)': {
    textAlign: 'center',
     fontSize: '2.5vw',
    fontWeight: 500,
     fontFamily: 'Open Sans',

  },
  '@media (max-width: 974px)': {
     textAlign: 'center',
    fontWeight: 500,
     fontSize: '2.5vw',
     fontFamily: 'Open Sans',
  },'@media (max-width: 918px)': {
    textAlign: 'center',
    fontSize: '2.5vw',
     fontWeight: 500,
     fontFamily: 'Open Sans',

  },
  '@media (max-width: 858px)': {
    textAlign: 'center',
     fontSize: '4vw',
    fontWeight: 500,
     fontFamily: 'Open Sans',


  },'@media (max-width: 768px)': {
    textAlign: 'center',
     fontSize: '4vw',
    fontFamily: 'Open Sans',
    fontWeight: 500,


  },'@media (max-width: 626px)': {
    textAlign: 'center',
    fontWeight: 500,
     fontFamily: 'Open Sans',
    fontSize: '4vw',

  },
  '@media (max-width: 468px)': {
    textAlign: 'center',
    fontWeight: 500,
    fontFamily: 'Open Sans',
     fontSize: '4vw',

  },'@media (max-width: 386px)': {
    textAlign: 'center',
     fontWeight: 500,
    fontFamily: 'Open Sans',
    fontSize: '4vw',
  },
 
})

const StyledTextGradient=styled(Typography)({
  background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  fontWeight: 700,
  WebkitBackgroundClip: 'text',
  fontFamily: 'Georgia',
  textAlign: 'center',
  fontSize: '4vw',

  '@media (max-width: 1034px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontFamily: 'Georgia',
  fontWeight: 700,
  textAlign: 'center',
  fontSize: '4vw',

  },'@media (max-width: 1018px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  fontWeight: 700,
  textAlign: 'center',
  fontFamily: 'Georgia',
  fontSize: '4vw',

  },
  '@media (max-width: 974px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Georgia',
    fontWeight: 700,
    WebkitBackgroundClip: 'text',
    textAlign: 'center',
    fontSize: '4vw',
  
  },'@media (max-width: 918px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  fontFamily: 'Georgia',
  fontWeight: 700,
  WebkitBackgroundClip: 'text',
  textAlign: 'center',
  fontSize: '4vw',

  },
  '@media (max-width: 858px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
    fontFamily: 'Georgia',
    WebkitTextFillColor: 'transparent',
  fontWeight: 700,
  textAlign: 'center',
  fontSize: '5vw',


  },'@media (max-width: 768px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  fontWeight: 700,
  textAlign: 'center',
  fontFamily: 'Georgia',
  fontSize: '5vw',
  },'@media (max-width: 626px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  fontWeight: 700,
  textAlign: 'center',
  fontFamily: 'Georgia',
  fontSize: '5vw',

  },
  '@media (max-width: 468px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Georgia',
    fontWeight: 700,
    WebkitBackgroundClip: 'text',
    textAlign: 'center',
    fontSize: '6vw',
  
  },'@media (max-width: 386px)': {
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Georgia',
    WebkitBackgroundClip: 'text',
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '6vw',
  
  },
 
})


const StyleJarvysInput=styled(Box)({
  
  border: "2px solid #434343",
   display: 'flex',
    justifyContent: 'center',
     alignItems: 'center',
    width: '45%',
    borderTopLeftRadius:'100px',
    borderBottomLeftRadius:'100px', 
    minHeight:"60px", 
   backgroundColor:'#FFFFFF',

  '@media (max-width: 700px)': {
    border: "2px solid #434343",
   display: 'flex',
    justifyContent: 'center',
    borderTopLeftRadius:'100px',
    borderBottomLeftRadius:'100px',
    borderRadius: "60px", 
     alignItems: 'center',
      width: '90%',
      margin: '20px 0px',
      height:"60px",
      backgroundColor:'#FFFFFF',
  },
  "& .jarvys_textField":{
    "& .MuiOutlinedInput-input": {
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    "& .MuiInputBase-inputMultiline": {
      scrollbarWidth: "none", 
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  }
})


const StyleJarvysParentBoxInput=styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
 '@media (max-width: 700px)': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection:'column',
    alignItems:'center',
  }
})

const StyleJarvysBoxButton=styled(Button)({

  color: '#FFFFFF',
  borderTopRightRadius: '100px',
  borderBottomRightRadius: '100px',
  fontFamily: 'Open Sans',
  padding: '6px 50px',
  cursor: 'pointer',
 '@media (max-width: 700px)': {
    borderRadius:'100px',
    padding: '20px 60px',
    width: "91%"
  }
 
})

const ModalLandingContainer = styled(Modal)({
     padding: "20px",
    display: "flex",
   justifyContent: "center",
    alignItems: "center",
  "& .closeJarvysLandingBtn": {
     position: "absolute",
     color: "black",
     top: "10px",
     right: "10px"
    },
   "& .JarvaysLandingPaper": {
     maxHeight: 'fit-content',
     width: "520px",
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     padding: "40px",
     borderRadius: "24px",
     gap: "20px 0px",
     position: "relative",
     boxSizing: "border-box",
      "@media (max-width: 600px)": {
       width:"420px",
     }, 
     "@media (max-width: 460px)": {
       width:"300px",
       padding: "20px",
     }, 
     },
   "& .jarvys_landing_platform_msg": {
     fontFamily: 'Open Sans',
     fontWeight: 600,
     textAlign: "center",
     fontSize: '18px',
     color: '#4D4D4D',
      marginTop: "40px",
       "@media (max-width: 460px)": {
         marginTop: "15px",
       }, 
    },
   "& .jarvaysLandingMsgBox": {
     background: 'linear-gradient(to right, #0066FF, #E8CC17)',
     WebkitBackgroundClip: 'text',
     WebkitTextFillColor: 'transparent',
     fontFamily: 'Open Sans',
     fontWeight: 600,
     textAlign: "center",
     fontSize: '48px !important',
     lineHeight: "65.37px !important",
      "@media (max-width: 460px)": {
        fontSize: "32px !important",
       }, 
    },
   "& .buttonJarvysLandingBox": {
       display: "flex",
       gap: "20px",
       marginTop: "30px",
      "& .signupLandingBtn": {
         color: "#000000",
         border: "1px solid #000000",
         cursor: "pointer"
     },
      "& .loginLandingBtn": {
         color: "white",
         backgroundColor: "#000000",
         cursor: "pointer"
     },
     "& .MuiButton-root": {
       textTransform: "none",
       borderRadius: "8px",
       lineHeight: "24px",
       fontWeight: 600,
       fontSize: "16px",
       width: "212px",
        height: "48px",
         "@media (max-width: 600px)": {
           width: "150px",
         },
         "@media (max-width: 460px)": {
           width: "100px",
         }, 
     },
    }
});

const TalkJarvysBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',  
  flexDirection: 'column',
})

// Customizable Area End 