import React from 'react';
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Dialog,
  DialogContent,
  IconButton
} from '@mui/material';
import { instaIcon, closeIcon, facebookIcon, twitterIcon, linkedInIcon } from './assets';
import ConnectedAccountsController, { Props, configJSON } from './ConnectedAccountsController.web';
// Customizable Area End

export default class ConnectedAccounts extends ConnectedAccountsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInstaAccount = () => {
    const redirectUrl = window.location.href;
    return (
      <AccountBox>
        <InstaBox>
          <img src={instaIcon} className="insta_img" />
          <Typography className='insta_text'>{configJSON.instagram}</Typography>
        </InstaBox>
        {this.state.isShowBtn ? <ConnectButton onClick={this.handleConnectButtonClick} data-testId="connectaccountTestID">
          <a className='link' href={`https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=810532164300660&redirect_uri=${redirectUrl}&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish`}>{configJSON.connect}</a>
        </ConnectButton>
        : <DisConnectButton onClick={this.handleOpenModal} data-testId="disconnectModalTestID">{configJSON.disconnect}</DisConnectButton>}
        {this.renderDisconnectModal()}
      </AccountBox>
    )
  }

  renderFacebookAccount = () => {
    const redirectUrl = window.location.href;
    return (
      <AccountBox>
        <InstaBox>
          <img src={facebookIcon} className="facebook_img" />
          <Typography className='fecebook_text'>{configJSON.facebook}</Typography>
        </InstaBox>
        {this.state.isShowBtn ? <ConnectButton onClick={this.handleConnectButtonClick} data-testId="connectaccountTestID">
         {configJSON.connect}
        </ConnectButton>
        : <DisConnectButton onClick={this.handleOpenfacebookModal} data-testId="openFacebookModal">{configJSON.disconnect}</DisConnectButton>}
        {this.renderDisconnectfecebookModal()}
      </AccountBox>
    )
  }

  renderTwitterAccount = () => {
    const { isTwitterBtn } = this.state;
    return (
      <AccountBox>
        <InstaBox>
          <img src={twitterIcon} className="insta_img" />
          <Typography className='twitter_text'>{configJSON.twitter}</Typography>
        </InstaBox>
        {isTwitterBtn ? <ConnectButton onClick={this.handleConnectTwitter} data-testId="connectTwitterTestID">
          {configJSON.connect}
        </ConnectButton>
        : <DisConnectButton onClick={this.handleOpenTwitterModal} data-testId="disconnectTwitterTestID">{configJSON.disconnect}</DisConnectButton>}
        {this.renderDisconnectTwitterModal()}
      </AccountBox>
    )
  }

  renderLinkedInAccount = () => {
    const { isLinkedInBtn } = this.state;
    return (
      <AccountBox>
        <InstaBox>
          <img src={linkedInIcon} className="insta_img" />
          <Typography className='linkedIn_text'>{configJSON.linkedIn}</Typography>
        </InstaBox>
        {isLinkedInBtn ? <ConnectButton onClick={this.handleConnectLinkedIn} data-testId="connectlinkedInTestID">
          {configJSON.connect}
        </ConnectButton>
        : <DisConnectButton onClick={this.handleOpenLinkedInModal} data-testId="disconnectlinkedInTestID">{configJSON.disconnect}</DisConnectButton>}
        {this.renderDisconnectLinkedInModal()}
      </AccountBox>
    )
  }

  renderDisconnectModal = () => {
    return (
      <StyledDialog
        onClose={this.handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.isDisconnect}
      >
        <IconBtn
          aria-label="close"
          onClick={this.handleCloseModal}
        >
          <img src={closeIcon} alt="close_icon" />
        </IconBtn>
        <DialogContent dividers>
          <ModalHeading>{configJSON.disConnectHeading}</ModalHeading>
          <ModalContent>{configJSON.disConnectContent}</ModalContent>
          <InstaBoxModal>
            <img src={instaIcon} className="insta_img" />
            <Typography className='insta_text_modal'>{configJSON.instagram}</Typography>
          </InstaBoxModal>
          <ButtonBox>
            <CancelButton onClick={this.handleCloseModal}>{configJSON.cancel}</CancelButton>
            <DisConnectModalBtn onClick={this.handleDisconnect} data-testId="disconnectaccountTestID">{configJSON.disconnect}</DisConnectModalBtn>
          </ButtonBox>
        </DialogContent>
      </StyledDialog>
    )
  }
  
  renderDisconnectfecebookModal = () => {
    return (
      <StyledDialog
        onClose={this.handleClosefacebookModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.isfacebookDisconnect}
      >
        <IconBtn
          aria-label="close"
          data-test-id='btnCloseModal'
          onClick={this.handleClosefacebookModal}
        >
          <img src={closeIcon} alt="close_icon" />
        </IconBtn>
        <DialogContent dividers>
          <ModalHeading>{configJSON.disConnectHeading}</ModalHeading>
          <ModalContent>{configJSON.disConnectContent}</ModalContent>
          <InstaBoxModal>
            <img src={facebookIcon} className="facebook_img" />
            <Typography className='facebook_text_model'>{configJSON.facebook}</Typography>
          </InstaBoxModal>
          <ButtonBox>
            <CancelButton onClick={this.handleClosefacebookModal}>{configJSON.cancel}</CancelButton>
            <DisConnectModalBtn onClick={this.handlefacebookDisconnect} data-testId="closeFacebookModal">{configJSON.disconnect}</DisConnectModalBtn>
          </ButtonBox>
        </DialogContent>
      </StyledDialog>
    )
  }

  renderDisconnectTwitterModal = () => {
    return (
      <StyledDialog
        onClose={this.handleCloseTwitterModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.isTwitterDisconnect}
      >
        <IconBtn
          aria-label="close"
          onClick={this.handleCloseTwitterModal}
        >
          <img src={closeIcon} alt="close_icon" />
        </IconBtn>
        <DialogContent dividers>
          <ModalHeading>{configJSON.disConnectHeading}</ModalHeading>
          <ModalContent>{configJSON.disConnectContent}</ModalContent>
          <InstaBoxModal>
            <img src={twitterIcon} className="insta_img" />
            <Typography className='twitter_text_model'>{configJSON.twitter}</Typography>
          </InstaBoxModal>
          <ButtonBox>
            <CancelButton onClick={this.handleCloseTwitterModal}>{configJSON.cancel}</CancelButton>
            <DisConnectModalBtn onClick={this.handleTwitterDisconnect} data-testId="twitterAccountTestID">{configJSON.disconnect}</DisConnectModalBtn>
          </ButtonBox>
        </DialogContent>
      </StyledDialog>
    )
  }

  renderDisconnectLinkedInModal = () => {
    return (
      <StyledDialog
        onClose={this.handleCloseLinkedInModal}
        aria-labelledby="customized-dialog-title"
        open={this.state.isLinkedInDisconnect}
      >
        <IconBtn
          aria-label="close"
          onClick={this.handleCloseLinkedInModal}
        >
          <img src={closeIcon} alt="close_icon" />
        </IconBtn>
        <DialogContent dividers>
          <ModalHeading>{configJSON.disConnectHeading}</ModalHeading>
          <ModalContent>{configJSON.disConnectContent}</ModalContent>
          <InstaBoxModal>
            <img src={linkedInIcon} className="insta_img" />
            <Typography className='linkedIn_text_model'>{configJSON.linkedIn}</Typography>
          </InstaBoxModal>
          <ButtonBox>
            <CancelButton onClick={this.handleCloseLinkedInModal}>{configJSON.cancel}</CancelButton>
            <DisConnectModalBtn onClick={this.handleLinkedInDisconnect} data-testId="linkedInAccountTestID">{configJSON.disconnect}</DisConnectModalBtn>
          </ButtonBox>
        </DialogContent>
      </StyledDialog>
    )
  }
  
  renderConnectedAccountsPage = () => {
    const {realSwitch, userSwitch, contentSwitch} = this.state.isSwitch;
    const {realfacebookSwitch, userfacebookSwitch, contentfacebookSwitch} = this.state.isfacebookSwitch;
    const {realTwitter, userTwitter, contentTwitter} = this.state.isTwitterSwitch;
    const {realLinkedIn, userLinkedIn, contentLinkedIn} = this.state.isLinkedInSwitch;
    const {active , paused} = configJSON;
    return (
      <MainContainer>
        <ConnectHeading>{configJSON.ConnectAccount}</ConnectHeading>
      <TableContainers>
      <AccountTable>
        <TableHead>
          <TableRow>
            <TableCellTH align="left">{configJSON.accountTH}</TableCellTH>
            <TableCellTH align="center">{configJSON.realTH}</TableCellTH>
            <TableCellTH align="center">{configJSON.userTH}</TableCellTH>
            <TableCellTH align="center">{configJSON.contentTH}</TableCellTH>
          </TableRow>
        </TableHead>
        <TableBody >
            <TableRow >
          
              <TableCell> {this.renderInstaAccount()} </TableCell>
              <TableCellTB align="center"><SwitchText>{realSwitch ? active : paused}</SwitchText><Switch className='switch_account' checked={realSwitch} data-testId="realTimeTestID" onChange={this.handleAccountSwitch} name="realSwitch"/></TableCellTB>
              <TableCellTB align="center"><SwitchText>{userSwitch ? active : paused}</SwitchText><Switch className='switch_account' checked={userSwitch} onChange={this.handleAccountSwitch} name="userSwitch"/></TableCellTB>
              <TableCellTB align="center"><SwitchText>{contentSwitch ? active : paused}</SwitchText><Switch className='switch_account' checked={contentSwitch} data-testId="contentCreationSwitch" onChange={this.handleAccountSwitch} name="contentSwitch"/></TableCellTB>
            </TableRow>
            <TableRow style={{ display: 'none'}}>

          <TableCell> {this.renderFacebookAccount()} </TableCell>
          <TableCellTB align="center"><SwitchText>{realfacebookSwitch ? active : paused}</SwitchText><Switch className='switch_account' checked={realfacebookSwitch} data-testId="facebookAccountSwitch" onChange={this.handlefacebookAccountSwitch} name="realfacebookSwitch"/></TableCellTB>
          <TableCellTB align="center"><SwitchText>{userfacebookSwitch ? active : paused}</SwitchText><Switch className='switch_account' checked={userfacebookSwitch} onChange={this.handlefacebookAccountSwitch} name="userfacebookSwitch"/></TableCellTB>
          <TableCellTB align="center"><SwitchText>{contentfacebookSwitch ? active : paused}</SwitchText><Switch className='switch_account' checked={contentfacebookSwitch} data-testId="contentCreationSwitch" onChange={this.handlefacebookAccountSwitch} name="contentfacebookSwitch"/></TableCellTB>
        </TableRow>

        <TableRow className='twitterBox'>
            <TableCell> {this.renderTwitterAccount()} </TableCell>
            <TableCellTB align="center"><SwitchText>{realTwitter ? active : paused}</SwitchText><Switch className='switch_account' checked={realTwitter} data-testId="realTwitterTestID" onChange={this.handleTwitter} name="realTwitter"/></TableCellTB>
            <TableCellTB align="center"><SwitchText>{userTwitter ? active : paused}</SwitchText><Switch className='switch_account' checked={userTwitter} onChange={this.handleTwitter} name="userTwitter"/></TableCellTB>
            <TableCellTB align="center"><SwitchText>{contentTwitter ? active : paused}</SwitchText><Switch className='switch_account' checked={contentTwitter} onChange={this.handleTwitter} name="contentTwitter"/></TableCellTB>
        </TableRow>

        <TableRow style={{ display: "none"}}>
            <TableCell> {this.renderLinkedInAccount()} </TableCell>
            <TableCellTB align="center"><SwitchText>{realLinkedIn ? active : paused}</SwitchText><Switch className='switch_account' checked={realLinkedIn} data-testId="realLinkedInTestID" onChange={this.handleLinkedIn} name="realLinkedIn"/></TableCellTB>
            <TableCellTB align="center"><SwitchText>{userLinkedIn ? active : paused}</SwitchText><Switch className='switch_account' checked={userLinkedIn} onChange={this.handleLinkedIn} name="userLinkedIn"/></TableCellTB>
            <TableCellTB align="center"><SwitchText>{contentLinkedIn ? active : paused}</SwitchText><Switch className='switch_account' checked={contentLinkedIn} onChange={this.handleLinkedIn} name="contentLinkedIn"/></TableCellTB>
        </TableRow>
        </TableBody>
      </AccountTable>
    </TableContainers>
    </MainContainer>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.renderConnectedAccountsPage()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  padding: "50px 30px"
})

const ConnectHeading = styled(Box)({
  marginTop: "24px",
  fontFamily: 'Open Sans',
  fontSize: '24px',
  fontWeight: 600,
  color: '#000000'
})

const AccountBox = styled(Box)({
  width: "250px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  borderRadius: "12px",
  padding: "8px 16px",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  "& .insta_img": {
    height: "20px"
  },
  "& .facebook_img": {
    width: '40px',
    height: '40px',
  },
  "& .insta_text": {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7153B8'
  },
  "& .fecebook_text": {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: '#1977F3'
  },
  "& .twitter_text": {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000'
  },
  "& .linkedIn_text": {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: '#0273AF'
  },

})

const InstaBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "12px"
})

const InstaBoxModal = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "12px",
  marginTop: "16px",
  "& .insta_text_modal": {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7153B8'
  },
  "& .facebook_text_model": {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24.51px',
    color:"#1977F3"
  },
  "& .twitter_text_model": {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '21.79px',
    color:"#000000"
  },
  "& .linkedIn_text_model": {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '21.79px',
    color: "#0273AF"
  }
})

const ConnectButton = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor: '#000000',
  color: "#FFFFFF",
  padding: "8px 16px",
  borderRadius: "8px",
  cursor: "pointer",
  ".link":{
    color:'#fff',
    fontSize:"14px",
    fontWeight:600,
    fontFamily:'Open sans',
    textDecoration: 'none'
  }
})

const DisConnectButton = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 700,
  color: "#000000",
  padding: "8px",
  borderRadius: "8px",
  cursor: "pointer",
  border: "1px solid #A8A8A8"
})

const InstaConnectBox = styled(Box)({
   display: "none",
   height: "100vh"
})

const TableContainers = styled(TableContainer)({
   marginTop: "30px",
   width: "100%",
   height: "100vh",
   padding:"0px 5px",
})

const AccountTable = styled(Table)({
  "& .MuiTableBody-root":{
    borderRadius: "16px !important",
    border: "1px solid #DFDFDF",
  },
  "& .MuiTableCell-head":{
    borderBottom : "none",
  },
  "& .MuiTableCell-body	":{
    borderBottom : "none",
    borderLeft: "1px solid #DFDFDF",
  },
  "& .twitterBox":{
    display: "none"
  },
})

const TableCellTH = styled(TableCell)({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 600,
  color: '#0E0E0E'
})

const SwitchText = styled('span')({
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontWeight: 600,
  color: '#4D4D4D'
})

const TableCellTB = styled(TableCell)({
  "& .switch_account":{
   gap:"20px",
   "&.MuiSwitch-root":{
     height:"28px !important",
     width:"48px !important",
     borderRadius: "20px !important",
     padding: "0px",
     backgroundColor: "#E2E8F0",
     opacity:1,
     alignItem: "center",
     justifyContent: "center",
     margin: "0px 5px"
   },
   "& .MuiButtonBase-root":{
     padding: "0px",
     width: "28px",
     height: "28px"
   },
   "& .MuiButtonBase-root.Mui-checked":{
      color: "#FFFFFF"
   },
   "& .MuiSwitch-thumb":{
     width: "24px",
     height: "24px"
   },
   "& .MuiButtonBase-root.Mui-checked + .MuiSwitch-track":{
     backgroundColor: "#000000",
     opacity: 1
   }
  }
})

const StyledDialog = styled(Dialog) ({
   textAlign: "center",
  "& .MuiDialog-paper": {
    width: '529px',
    borderRadius: "24px",
    boxSizing: 'border-box',
  },
  '& .MuiDialogContent-root': {
      padding: '40px 40px',
  },
});

const IconBtn = styled(IconButton)({
  position: 'absolute',
  right: 16,
  top: 16,
});

const ModalHeading = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '20px',
  fontWeight: 600,
  color: "#4D4D4D",
})

const ModalContent = styled(Typography)({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 400,
  color: "#4D4D4D",
  marginTop:"16px"
})

const ButtonBox = styled(Box)({
  display: "flex",
  marginTop: "35px",
  width: "100%",
  justifyContent: "space-evenly",
  gap: "20px"
})

const CancelButton = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 600,
  backgroundColor: '#000000',
  color: "#FFFFFF",
  padding: "10px 16px",
  borderRadius: "8px",
  cursor: "pointer",
  flex: "fit-content"
})

const DisConnectModalBtn = styled(Box)({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 700,
  color: "#000000",
  padding: "10px",
  borderRadius: "8px",
  cursor: "pointer",
  border: "1px solid #A8A8A8",
  flex: "fit-content"
})


// Customizable Area End 