// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { topIcon1, topIcon2, topIcon3, } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";


export const webConfigJSON = require("./config.js");

interface CommunityForumAttributes {
  id: number;
  name: string;
  description: string;
  serious_mode: string;
  fun_mode: string;
  web_link: string;
  status: string;
  created_at: string;
  updated_at: string;
  link_account: number[];
  brand_guideline: {
    id: number;
    filename: string;
    content_type: string;
  }[];
  product_price: {
    id: number;
    filename: string;
    content_type: string;
  }[];
}

export interface Props {
  navigation: any;

}
interface S {

  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  topHeadings: {
    title: string,
    desc: string,
    icon: string,
  }[];
  current_CM_list: {
    id: string;
    type: string;
    attributes: CommunityForumAttributes;
  }[]
  isModalOpen: boolean,
  currentCmName: string,
  isDeleteClicked: boolean,
  current_cm_id: string
  createText: string;
  communityVideoUrl: string;
}
interface SS { }

export default class DashboardCommunityManagerController extends BlockComponent<Props, S, SS> {
  getAllCommunityApiCallId: string = "";
  deleteCommunityApiCallId: string = "";
  changeStatusCommunityApiCallId: string = "";
  getVideoApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      topHeadings: [
        {
          title: 'Personalized  User Experiences:',
          desc: 'Use AI to tailor interactions based on user behavior and preferences, thereby enhancing customer satisfaction with personalized touchpoints.',
          icon: topIcon1,
        },
        {
          title: 'Automated Efficiency:',
          desc: 'Automate common queries and responses to improve efficiency, allowing the system to handle customer inquiries 24/7 without human intervention.',
          icon: topIcon2,
        },
        {
          title: 'Enhanced Engagement:',
          desc: 'Increase user engagement with personalized content and timely responses, ensuring a more interactive and satisfying user experience.',
          icon: topIcon3,
        },
      ],
      current_CM_list: [],
      isModalOpen: false,
      currentCmName: '',
      isDeleteClicked: false,
      current_cm_id: '',
      createText: '',
      communityVideoUrl: '',
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("API Message Recived", message);

    this.getAllCommunityReceive(message);
    this.deleteCommunityReceive(message);
    this.changeStatusReceive(message);
    this.getCommunityVideoReceive(message);

    // Customizable Area End
  }

  getAllCommunityReceive = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    ) === this.getAllCommunityApiCallId) {
      if (responseJson && responseJson.data) {
        this.setState({ current_CM_list: responseJson.data })
      } else {
        this.setState({ current_CM_list: [] })
      }
    }
  }

  deleteCommunityReceive = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.deleteCommunityApiCallId) {
      if (responseJson && responseJson.message === 'Community deleted successfully.') {
        this.setState({ isDeleteClicked: true })
        this.getAllCommunity();
      }
    }
  }

  changeStatusReceive = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.changeStatusCommunityApiCallId) {
      if (responseJson && (responseJson.message === 'Community active successfully.' || responseJson.message === 'Community inactive successfully.')) {
        this.getAllCommunity();
      }
    }
  }

  getCommunityVideoReceive = (message: Message) => {
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    ) === this.getVideoApiCallId) {
      if (response && response.iframe) {
        this.setState({
          communityVideoUrl: response.iframe
        })
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();

    let authToken = await getStorageData('authToken')
    this.setState({ token: authToken });

    this.getAllCommunity();
    this.getCommunityVideo();
  }
  handleCloseDeleteModal = () => {
    this.setState({ isModalOpen: false })
  }

  handleOpenDeleteModal = (text: string, communityId: string) => {
    this.setState({ isModalOpen: true, isDeleteClicked: false, currentCmName: text, current_cm_id: communityId })
  }

  redirectToCreate = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "NewCommunityManager");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }


  getAllCommunity = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCommunityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.CommunityEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteCommunity = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCommunityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.CommunityEndPoint}/${this.state.current_cm_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.deleteCommunityMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  formatDate = (timestamp: string): string => {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };

    const formattedDate = date.toLocaleString('en-GB', options);

    return formattedDate.replace(' at ', ' ');
  };

  handleSwitchChange = (communityId: string | number) => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeStatusCommunityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.updateCommunityEndPoint}?id=${communityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getCommunityMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCommunityVideo = () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVideoApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getCommunityVideoEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getCommunityMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
// Customizable Area End