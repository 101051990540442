import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
import { getStorageData } from "../../../framework/src/Utilities"


export interface ApiData{
    contentType: string;
    method: string;
    endPoint: string; 
    body?: {}
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    accessToken: string;
    error: string;
    isSwitch: {
        realSwitch: boolean,
        userSwitch: boolean,
        contentSwitch: boolean
    };
    isfacebookSwitch: {
      realfacebookSwitch: boolean,
      userfacebookSwitch: boolean,
      contentfacebookSwitch: boolean
  };
    isTwitterSwitch: {
       realTwitter: boolean,
       userTwitter: boolean,
       contentTwitter: boolean
    };
    isLinkedInSwitch: {
      realLinkedIn: boolean;
      userLinkedIn: boolean;
      contentLinkedIn: boolean;
    },
    isDisconnect: boolean;
    isfacebookDisconnect: boolean;
    isShowBtn: boolean;
    isfacebookShowBTn: boolean;
    isTwitterDisconnect: boolean;
    isTwitterBtn: boolean;
    isLinkedInBtn: boolean;
    isLinkedInDisconnect: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ConnectedAccountsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    instagramRef: React.RefObject<HTMLDivElement>;
    apiGetConnectedPlatformId: string = "";
    apiFetchTokenId: string = "";
    apiSaveTokenId: string = "";
    authToken: string = "";
    userId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            accessToken: "",
            error: "",
            isSwitch: {
                realSwitch: true,
                userSwitch: false,
                contentSwitch: true
            },
            isfacebookSwitch: {
              realfacebookSwitch: true,
              userfacebookSwitch: false,
              contentfacebookSwitch: true
          },
            isTwitterSwitch: {
              realTwitter: true,
              userTwitter: false,
              contentTwitter: true
            },
            isLinkedInSwitch: {
              realLinkedIn: true,
              userLinkedIn: false,
              contentLinkedIn: true
            },
            isDisconnect: false,
            isfacebookDisconnect: false,
            isTwitterDisconnect: false,
            isLinkedInDisconnect: false,
            isShowBtn: true,
            isfacebookShowBTn: true,
            isTwitterBtn : true,
            isLinkedInBtn : true,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.instagramRef = React.createRef();
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(apiRequestCallId === this.apiGetConnectedPlatformId){
                if(responseJson.length > 0){
                  this.setState({isShowBtn:false});
                }
            }if(apiRequestCallId === this.apiFetchTokenId){
                this.saveToken(responseJson.access_token, responseJson.user_id.toString());
            } if(apiRequestCallId  === this.apiSaveTokenId){
                this.setState({isShowBtn:false});
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        const url = window.location.href;
        const params = new URLSearchParams(new URL(url).search);
        const code = params.get('code');
        if(code){
          this.fetchToken(code);
          params.delete('code');
          const newUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
          window.history.replaceState({}, document.title, newUrl);
        }

        this.authToken = await getStorageData('authToken');
        this.userId = await getStorageData('userId');
        this.getConnectedPlatforms();
      }

    handleConnectButtonClick = () => {
        this.setState({isShowBtn : false});
    };

    handleConnectTwitter = () => {
      const redirectUrl = window.location.href;
      this.setState({isTwitterBtn : false}, ()=>{
        const twitterAuthUrl = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=UL816vPNi6W4fskVuO6pv1OZk&redirect_uri=${redirectUrl}&scope=tweet.read%20users.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`;
        window.location.href = twitterAuthUrl;
      });
    };

    handleConnectLinkedIn = () => {
      const redirectUrl = window.location.href;
      this.setState({ isLinkedInBtn: false }, () => {
        const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86k1ku5auyhciu&redirect_uri=${redirectUrl}&scope=w_member_social`;
        window.location.href = linkedInAuthUrl;
      });
    };

    handleCloseModal = () =>{
       
        this.setState({ isDisconnect: false});
      }
      handleClosefacebookModal = () =>{
      
        this.setState({ isfacebookDisconnect: false});
      }
    handleOpenModal = () =>{

        this.setState({ isDisconnect: true});
      }
      
    handleOpenfacebookModal = () =>{
        this.setState({ isfacebookDisconnect: true});
      }
    
    handleOpenTwitterModal = () =>{
        this.setState({ isTwitterDisconnect: true});
    }

  handleOpenLinkedInModal = () => {
    this.setState({ isLinkedInDisconnect: true });
  }

  handleCloseLinkedInModal = () => {
    this.setState({ isLinkedInDisconnect: false });
  }

   handleLinkedInDisconnect = () => {
     this.setState({ isLinkedInBtn: true });
    this.handleCloseLinkedInModal();
   }
  
    handleCloseTwitterModal = () =>{
      this.setState({ isTwitterDisconnect: false});
    }

    handleTwitterDisconnect = () =>{ 
      this.setState({ isTwitterBtn: true });
      this.handleCloseTwitterModal();
  }
    
    handleDisconnect = () =>{ 
      
        this.setState({ isShowBtn: true });
        this.handleCloseModal();
    }
  
    handlefacebookDisconnect = () =>{ 
    
       this.setState({ isfacebookShowBTn: true });
       this.handleClosefacebookModal();
   }
    handleAccountSwitch = (event : React.ChangeEvent<HTMLInputElement>) => {
    
        this.setState({ 
            isSwitch: { 
              ...this.state.isSwitch, 
              [event.target.name]: event.target.checked 
            } 
          });
      };

      handlefacebookAccountSwitch = (event : React.ChangeEvent<HTMLInputElement>) => {
       
        this.setState({ 
            isfacebookSwitch: { 
              ...this.state.isfacebookSwitch, 
              [event.target.name]: event.target.checked 
            } 
          });
      };
    
    handleTwitter = (event: React.ChangeEvent<HTMLInputElement>) => {
       this.setState({
          isTwitterSwitch: {
               ...this.state.isTwitterSwitch,
              [event.target.name]: event.target.checked
         }
       });
     };

    handleLinkedIn = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
         isLinkedInSwitch: {
              ...this.state.isLinkedInSwitch,
             [event.target.name]: event.target.checked
        }
      });
    };
    
    getConnectedPlatforms = () => {
        this.apiGetConnectedPlatformId = this.apiCall({
            contentType: configJSON.ApiContentTypeJson,
            method: "GET",
            endPoint: `/bx_block_settings/platforms`
          })
    }

    fetchToken = (code:string) => {
        const form = new FormData();
        form.append("code", code)

        const header = {
            token: this.authToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiFetchTokenId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_chatgpt3/thread_chatgpt/insta_access_token`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            form
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    saveToken = (token: string, reciverId: string) => {
        this.apiSaveTokenId = this.apiCall({
            contentType: configJSON.exampleApiContentType,
            method: "POST",
            endPoint: `/bx_block_settings/platforms`,
            body:{
                "platform":{
                    "platform_name":"Instagram",
                    "access_token": token,
                    "reciver_id": reciverId
                }
            }
          })
    }


    apiCall = (data: ApiData) => {
        const { contentType, method, endPoint, body } = data;
        const token =  localStorage.getItem("authToken") || "";
        const header = {
          "Content-Type": contentType,
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
    // Customizable Area End
}