import React from "react";
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Post } from "../CampaignsResultsController";
import MenuButton from "./MenuButton";
import { noImage, playIcon } from "../assets";

interface Props {
    onRename: (id:number, newName: string) => void
    onDelete: (id: string) => void
    onEdit: (id: string) => void
    onClick: () => void,
    post: Post;
}

const PostCard: React.FC<Props> = ({ post, onRename, onDelete, onEdit, onClick }) => {

    const handleDelete = () => {
        onDelete(post.id);
    }

    const handleRename = (name: string) => {
        onRename(post.attributes.id, name);
    }

    const handleEdit = () => {
        onEdit(post.id);
    }

    return (
        <StyledCampaignResultCard>
            <Box sx={{ display: "flex", width: "100%", height: "100%", flexDirection: "column", gap: "12px", justifyContent: "space-between" }}>
                {(post.attributes.post_images.length === 0 && post.attributes.post_videos.length > 0) ?
                    <Box sx={{ position: 'relative' }}>
                        <img src={playIcon} alt="" style={webStyle.playIcon} />
                        <video src={post.attributes.post_videos[0].video_url} style={webStyle.video} />
                    </Box> : <>
                        {(post.attributes.post_images.length > 0) ?
                            <img src={post.attributes.post_images[0].image_url} style={webStyle.image} /> :
                            <img src={noImage} style={webStyle.image} />
                        }
                    </>}
                <Box sx={{ display: 'flex', width: "100%", justifyContent: "space-between", alignItems: "center", position: "relative" }}>
                    <Typography sx={webStyle.postTxt}>
                        {post.attributes.post_type}
                    </Typography>
                    <MenuButton data-testid="menuBtn" onDelete={handleDelete} onRename={handleRename} onEdit={handleEdit} from="post" contentName={post.attributes.name}/>
                </Box>
                <Typography sx={{...webStyle.f16, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{post.attributes.name}</Typography>
                <Typography sx={webStyle.f12}>Status: <span style={{fontWeight:600}}>{post.attributes.draft}</span></Typography>
            </Box>
            </StyledCampaignResultCard>
    );
}

export default PostCard;

const StyledCampaignResultCard = styled(Box)({
    width: "210px",
    border: "1px solid #DDDDDD",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    background: "#FFFDD8",
    borderRadius: "8px",
    padding: "12px 16px 12px 16px",
    gap: "36px",
    '&:hover': {
        background: "#FCF670",
        cursor: "pointer"
    },
    '@media (max-width: 363px)': {
        width: 'clamp(150px, 70vw, 260px)',
    }
})

const webStyle: { [key: string]: React.CSSProperties } = {
    f16: {
        fontSize:'16px',
        fontWeight:600,
        color:'#000000',
        fontFamily:'Open sans'
      },
    f12: {
        fontSize:'12px',
        fontWeight: 400,
        color: '#4D4D4D',
        fontFamily:'Open sans'
    },
    video: {
        height: '165px',
        width: '210px',
        borderRadius: '8px',
        objectFit: 'cover'
    },
    playIcon:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform: 'translate(-50%, -50%)'
    },
    image:{
        height: '165px',
        width: '210px',
        borderRadius: '8px',
        objectFit: 'cover'
    },
    postTxt:{ 
        fontSize: "12px",
        fontFamily: "Open Sans",
        fontWeight: 400,
        borderRadius: "100px",
        background: "#FFFBAA",
        color: "#4D4D4D",
        padding: "2px 8px",
        textTransform:'capitalize'
    }
}