import React from "react";

import {
  Box,
  // Customizable Area Start
  Grid,
  styled,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { logoImage,contentCreationICon, myProfileIcon, businessIcon, settingIcon, homeIcon, latestUpdateIcon, helpCenterIcon, logoutIcon, subcribeFileIcon, rightArrowIcon, downArrowIcon, closeIcon, userEngagementTool } from './assets';
const configJSON = require("./config.js");
import Headerauthuser from "../../../components/src/HeaderAuthorizeduser"
// Customizable Area End

import BreadcrumbNavigationPageController, {
  Props,
} from "./BreadcrumbNavigationPageController.web";

export default class BreadcrumbNavigationPage extends BreadcrumbNavigationPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSideNavigation = () => {
    return (
      <MainDiv>
        <Grid container className="grid_container">
          <Grid className="menu_container" >
            <LogoImgBox>
              <img src={logoImage} data-testId="logoIconTestID" onClick={this.goToLandingPage} alt="logoImage" className="logo_img" />
            </LogoImgBox>
            <NavBox>
            {this.renderNavBarTop()}
            {this.renderNavBarBottom()}  
            </NavBox>
          </Grid>
        </Grid>
      </MainDiv>
    )
  }

  renderMobileNavigation = () => {
    return (<>
      <DrawerBox open={this.state.menuToggle} onClose={this.handleToggleDrawer}>
        <MainContainer>
          <Grid container className="grid_container">
            <Grid className="menu_container" >
             <LogoBox>
              <LogoImgBox>
                <img src={logoImage} data-testId="logoIconTestID" onClick={this.goToLandingPage} alt="logoImage" className="logo_img" />
              </LogoImgBox>
              <CloseImgBox data-testId="SideBarCloseTestID" onClick={this.handleToggleDrawer}>
              <img src={closeIcon} className="close_img" />
            </CloseImgBox>
             </LogoBox>
          <NavBox>
          {this.renderNavBarTop()}
          {this.renderNavBarBottom()}
          </NavBox>
            </Grid>
          </Grid>
        </MainContainer>
      </DrawerBox>
    </>)
  }

  renderCircleBox = () =>{
    return(<CircleBox><Circle/></CircleBox>)
  }

  renderNavBarTop = () => {
    const { currentRoute } = this.state;
    return (<>
      <NavTop>
        <NavItemBox onClick={()=>{this.props.navigation.navigate("CustomisableUserProfileEdit")}}>
          {currentRoute === "CustomisableUserProfileEdit" && this.renderCircleBox()}
          <img src={myProfileIcon} className="nav_logo_img" />
          <NavItem>{configJSON.profile}</NavItem>
        </NavItemBox>
        <NavItemBox>
          <AccordionBox>
            <AccordionSummary expandIcon={<img src={downArrowIcon} className="nav_logo_img" />} >
              <img src={businessIcon} className="nav_logo_img" />
              <NavItem>{configJSON.business}</NavItem>
            </AccordionSummary>
            <AccordionDetails>
              <AccItem isActive={false} >{configJSON.myworkSpace}</AccItem>
            </AccordionDetails>
          </AccordionBox>
        </NavItemBox>

        <NavItemBox>
            {this.showUserEngagementTool() && this.renderCircleBox()}
            <img src={userEngagementTool} className="nav_logo_img"/>
            <NavItem data-testId='goToCommunityManager' onClick={this.goToCommunityManager}>{'User Engagement Tools'}</NavItem>
        </NavItemBox>

        <NavItemBox>
          <AccordionBox>
            <AccordionSummary expandIcon={<img src={downArrowIcon} className="nav_logo_img" />} >
            {this.showCircleContent() && this.renderCircleBox()}
              <img src={contentCreationICon} className="nav_logo_img"/>
              <NavItem>{'Content Creation '}</NavItem>
            </AccordionSummary>
            <AccordionDetails>
              <AccItem isActive={this.showActiveContent()} onClick={this.goToGenerateContent} data-testId="generateContentTestID">{configJSON.generateContent}</AccItem>
              <AccItem isActive={currentRoute === "PhotosLibrary"} onClick={this.goToMyLibrary} data-testId="myLibraryTestID">{configJSON.myLibrary}</AccItem>
              <AccItem  onClick={()=>{this.props.navigation.navigate("Campaigns")}
              }  isActive={this.showActiveCampaign()}>{'My Campaigns'}</AccItem>
              <AccItem isActive={currentRoute === "seorecommendations"} onClick={this.goToSeoRecommendations} data-testId="generateContentTestID">{configJSON.seoRecommendations}</AccItem>
            </AccordionDetails>
          </AccordionBox>
        </NavItemBox>
        <NavItemBox>
          {currentRoute === "" && this.renderCircleBox()}
          <img src={homeIcon} className="nav_logo_img" />
          <NavItem>{configJSON.home}</NavItem>
        </NavItemBox>
        <NavItemBox>
          <AccordionBox>
            <AccordionSummary expandIcon={<img src={downArrowIcon} className="nav_logo_img" />} >
            {this.showCircleSettings() && this.renderCircleBox()}
              <img src={settingIcon} className="nav_logo_img" />
              <NavItem>{configJSON.settings}</NavItem>
            </AccordionSummary>
            <AccordionDetails className="acc_details">
              <AccItem isActive={false}>{configJSON.security}</AccItem>
              <AccItem isActive={false}>{configJSON.notifications}</AccItem>
              <AccItem isActive={false}>{configJSON.membership}</AccItem>
              <AccItem isActive={currentRoute === "ConnectedAccounts"} onClick={this.goToConnectedAccount} data-testId="ConnectTestID">{configJSON.connectedAccounts}</AccItem>
            </AccordionDetails>
          </AccordionBox>
        </NavItemBox>
      </NavTop>
    </>)
  }

  renderNavBarBottom = () => {
    const { currentRoute } = this.state;
    return (<>
      <NavBottom>
        <SubscriptionMainBox>
          <SubscriptionBox>
            <ContentBox>
              <Typography className="subscription_content">{configJSON.subscription}</Typography>
              <Typography className="getMore_content">{configJSON.getMore}</Typography>
              <Typography className="content">{configJSON.content}</Typography>
            </ContentBox>
            <FileIcon>
              <img src={subcribeFileIcon} className="subcribe_file_img" />
            </FileIcon>
            <RightIcon>
              <img src={rightArrowIcon} className="right_arrow_img" />
            </RightIcon>
          </SubscriptionBox>
        </SubscriptionMainBox>
        <NavItemBox>
          <img src={latestUpdateIcon} className="nav_logo_img" />
          <NavItem>{configJSON.latestUpdates}</NavItem>
        </NavItemBox>
        <NavItemBox>
          <AccordionBox>
            <AccordionSummary expandIcon={<img src={downArrowIcon} className="nav_logo_img" />} >
            {this.showCircleHelpCenter() && this.renderCircleBox()}
              <img src={helpCenterIcon} className="nav_logo_img" />
              <NavItem>{configJSON.helpCenter}</NavItem>
            </AccordionSummary>
            <AccordionDetails className="acc_details">
              <AccItem isActive={currentRoute === "Interactivefaqs"} onClick={this.goToInteractivefaqs} data-testId="faqTestID">{configJSON.faqHeading}</AccItem>
              <AccItem isActive={currentRoute === "Onboardingguide"} onClick={this.goToOnboardingguide} data-testId="tutorialsTestID">{configJSON.tutorials}</AccItem>
              <AccItem isActive={false}>{configJSON.contactUs}</AccItem>
            </AccordionDetails>
          </AccordionBox>
        </NavItemBox>
        <NavItemBox onClick={this.handleLogOut.bind(this)} data-testId='logoutButton'>
          <img src={logoutIcon} className="nav_logo_img" />
          <NavItemLogout data-testId='logoutLabelButton'>{configJSON.logout}</NavItemLogout>
        </NavItemBox>
      </NavBottom>
    </>)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={{height:'100vh', display:"flex", width:"100%"}}>
        <SideNavigationBox>
        {this.renderSideNavigation()}
        </SideNavigationBox>
        <MobileNavigationBox>
        {this.renderMobileNavigation()}
        </MobileNavigationBox>
        <Box sx={{width:"100%", height:"100%",flexGrow:1, overflowY:"auto", scrollbarWidth: "none"}}>
        <Headerauthuser currentRoute={this.state.currentRoute} handleopendrawer={this.handledrweropensidebar} data-testid="opendrawer" fetchUserName={this.state.fetchUserName} fetchImageUrl={this.state.fetchImageUrl}/>
          {this.props.children}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CircleBox = styled(Box)({
  display:"flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "-5px"
})
const Circle = styled(Box)({
  width: "5px",
  height: "5px",
  borderRadius: "100%",
  background: "#FFFFFF",
})
const SideNavigationBox = styled(Box)({
  height:"100%",
  maxWidth:"fit-content",
  overflowY:"auto",
  scrollbarWidth: "none",
   display: "block",
   "@media (max-width: 899px)": {
   display: "none",
  },
})

const MobileNavigationBox = styled(Box)({
   display: "none !important",
   "@media (max-width: 899px)": {
    display: "block",
   },
})

const MainDiv = styled(Box)({
  width: '100%',
  "& .menu_container": {
    background: "#0E0E0E",
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    weight: 600,
    padding: "50px 0px",
  },
  "& .grid_container": {
    height: "100vh"
  }
})

const LogoBox = styled(Box)({
   position: "relative"
})


const LogoImgBox = styled(Box)({
  height: "100px",
  color: "#FFFFFF",
  textAlign: "center",
  "& .logo_img": {
    height: "70px",
    color: "#FFFFFF",
    cursor: "pointer",
  }
})

const CloseImgBox = styled(Box)({
  position: "absolute",
  top: 20,
  left: 30,
  color: "#FFFFFF",
  display: "flex",
  cursor: "pointer",
  "& .close_img": {
    height: "25px",
    color: "#FFFFFF"
  }
})

const AccordionBox = styled(Accordion)({
  "&.MuiPaper-root": {
    background: "none !important"
  },
  "& .MuiAccordionSummary-root": {
    padding: "0px",
    justifyContent: "start",
  },
  "& .MuiAccordionSummary-content": {
    alignItems: "center",
    flexGrow: 0
  },
  "& .acc_details": {
    display: "flex",
    flexDirection: "column"
  },
  "& .MuiAccordionSummary-expandIconWrapper":{
    marginTop:"4px",
  }
})

const NavBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "last-baseline",
  justifyContent: "space-between"
})

const NavTop = styled(Box)({
  width: "100%",
})

const NavBottom = styled(Box)({
  width: "100%",
  marginTop: "100px"
})

const NavItemBox = styled(Box)({
  margin: "10px 0px",
  display: "flex",
  cursor: "pointer",
  padding: "0 20px",
  "& .nav_logo_img": {
    height: "20px",
    margin: "0px 5px 0px 2px",
  },
  "& .nav_logoSVG_img": {
    height: "12px"
  },
  "& .active_pointer":{
    backgroundColor: "#FFFFFF",
    width: "8px",
    height: "8px",
    borderRadius: "50%"
  }
})

const NavItem = styled(Box)({
  fontFamily: "Open Sans",
  weight: 600,
  fontSize: "16px",
  color: "#FFFFFF",
})

const AccItem = styled(Box)<{isActive: boolean}>(({ isActive }) => ({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "16px",
  color: isActive ? "#FCF670" : "#FFFFFF",
  padding: "8px 0px",
  paddingLeft: "15px",
  marginLeft: "-4px",
  borderLeft: `2px solid ${isActive ? "#FCF670" : "#4D4D4D"}`,
}));

const NavItemLogout = styled(Box)({
  fontFamily: "Open Sans",
  weight: 600,
  fontSize: "16px",
  color: "#F06969",
})

const SubscriptionMainBox = styled(Box)({
  backgroundColor: "#2B28D64D",
  paddingLeft: "20px",
  display: "none"
})

const SubscriptionBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#212121",
  padding: "25px 15px",
  gap: "10px"
})

const ContentBox = styled(Box)({
  "& .subscription_content": {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#FFFFFF"
  },
  "& .getMore_content": {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#989898",
    marginTop: "10px"
  },
  "& .content": {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#3164E8"
  }

})

const FileIcon = styled(Box)({
  background: "#B7B7B726",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  "& .subcribe_file_img": {
    width: "22px",
    height: "22px",
    padding: "15px"
  }
})

const RightIcon = styled(Box)({
  marginLeft: "5px",
  "& .right_arrow_img": {
    width: "9px",
    height: "15px"
  }
})

const MainContainer = styled(Box)({
  width: '100%',
  "& .menu_container": {
    background: "#0E0E0E",
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    weight: 600,
    padding: "50px 0px",
  },
  "& .grid_container": {
    height: "100vh"
  }
})

const DrawerBox = styled(Drawer)({
   "& .MuiDrawer-paper":{
    scrollbarWidth: "none",
   }
})

// Customizable Area End
