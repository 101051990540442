// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities"
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
export const configJSON = require("./config");


export interface Props {
  navigation: any;
}

export interface Image {
  id: number;
  index: number,
  image_url: string;
  filename: string;
  content_type: string;
  byte_size: number
}

export interface Caption {
  id: number;
  post_id: number;
  content: string;
  selected: boolean
  created_at: string;
  updated_at: string;
}

export interface ContentDetails {
  id: number
  type: string
  attributes: {
    id: number
    name: string;
    content: string;
    post_type: string;
    draft: boolean;
    created_at: string;
    status:string,
    updated_at: string;
    scheduled_at: string | null,
    published_at: null | string,
    social_media_platform: {
      id: number;
      name: string;
      account_id: number;
      created_at: string;
      updated_at: string;
    },
    captions: Caption[],
    post_images: Image[]
    post_videos: {
      id: number;
      video_url: string;
      filename: string;
      content_type: string;
      byte_size: number
    }
  }
}


interface S {
  loading: boolean;
  isRegeneratingCaption: boolean,
  isEditContent: boolean;
  contentImages: Image[];
  activeCaptionIndex: number,
  contentName: string,
  contentCaptions: Caption[],
  contentDetails: ContentDetails | null,
  isOpenRegenerateImageModal: boolean,
  regenerateImageId: string,
  regenerateImageDescription: string;
  isRegeneratingImage: boolean;
  postSchedule: Date | null,
  meridiem: string,
  postStatus:string,
}

interface postNowResponse {
  success: boolean,
  statusCode: number,
  message: string,
}

interface SS {
  idManagement: number | string;
}
export default class ContentManagementEditController extends BlockComponent<
  Props,
  S,
  SS
> {

  authToken: string = "";
  userId: string = "";
  contentId: string = "";
  getContentApiCallId: string = "";
  regenerateCaptionApiCallId: string = "";
  regenerateImageApiCallId: string = "";
  saveCaptionApiCallId: string = ""
  deleteImageApiCallId: string = "";
  saveAsDraftApiCallId: string = "";
  schedulePostApiCallId: string = "";
  postNowApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isEditContent: false,
      loading: false,
      contentName: "Post Name",
      contentImages: [],
      activeCaptionIndex: 0,
      contentCaptions: [],
      contentDetails: null,
      isRegeneratingCaption: false,
      isOpenRegenerateImageModal: false,
      regenerateImageId: "",
      regenerateImageDescription: "",
      isRegeneratingImage: false,
      postSchedule: null,
      meridiem: "",
      postStatus:"Draft",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  async componentDidMount() {
    this.authToken = await getStorageData('authToken');
    this.userId = await getStorageData('userId');
    const queryParams = new URLSearchParams(this.props.navigation.state.searchParam);
    const contentId = queryParams.get("id")
    if (contentId) {
      this.contentId = contentId
      this.getContentDetails()
    } else {
      this.props.navigation.goBack()
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getContentApiCallId) {
        this.handleGetContentDetailsResponse(responseJson)
      } else if (apiRequestCallId === this.saveCaptionApiCallId || apiRequestCallId === this.regenerateCaptionApiCallId) {
        this.setState({
          contentCaptions: responseJson.data.attributes.captions,
          contentDetails: responseJson.data,
          isEditContent: false,
          isRegeneratingCaption: false,
        })
        toast.success("Caption updated successfully!")
      } else if (apiRequestCallId === this.regenerateImageApiCallId) {
        this.handleRegenerateImageResponse(responseJson)
      } else if (apiRequestCallId === this.deleteImageApiCallId) {
        responseJson.data.attributes.post_images.sort((item1: Image, item2: Image) => item1.index - item2.index)
        this.setState({
          contentImages: responseJson.data.attributes.post_images,
          contentDetails: responseJson.data,
        })
        toast.success("Image deleted successfully!")
      } else if (apiRequestCallId === this.saveAsDraftApiCallId) {
        this.handleSaveTODraftResponse(responseJson)
      } else if (apiRequestCallId === this.schedulePostApiCallId) {
        this.handleSchedulePostResponse(responseJson)
      } else if (apiRequestCallId === this.postNowApiCallId) {
        this.handlePosNowtResponse(responseJson)
      }
    }
  }

  handlePosNowtResponse(response: postNowResponse) {
    if (response.success) {
      this.getContentDetails()
      toast.success(response.message)
    } else {
      toast.error(response.message)
    }
  }

  handleSchedulePostResponse(response: { data?: ContentDetails, error?: string }) {
    if (response.error) {
      toast.error(response.error)
    } else if (response.data) {
      toast.success("Post has been scheduled!")
      this.props.navigation.goBack()
    } else {
      toast.error("Something went wrong, Please try again!")
    }
  }

  handleRegenerateImageResponse(response: { data?: ContentDetails, error?: string }) {
    if (response.error) {
      toast.error(response.error)
    } else if (response.data) {
      response.data.attributes.post_images.sort((item1: Image, item2: Image) => item1.index - item2.index)
      this.setState({
        contentImages: response.data.attributes.post_images,
        contentDetails: response.data,
      })
      toast.success("Image regenerated successfully!")
    } else {
      toast.error("Something went wrong, Please try again!")
    }
    this.setState({ isRegeneratingImage: false })
  }
  handleGetContentDetailsResponse(responseJson: { data: ContentDetails }) {
    responseJson.data.attributes.post_images.sort((item1: Image, item2: Image) => item1.index - item2.index)
    const scheduleTime = responseJson.data.attributes.scheduled_at ? new Date(responseJson.data.attributes.scheduled_at) : null
    let amPm = ""
    if (scheduleTime) {
      if (scheduleTime.getHours() >= 12) {
        amPm = "PM"
      } else {
        amPm = "AM"
      }
    }
    this.setState({
      contentName: responseJson.data.attributes.name,
      contentImages: responseJson.data.attributes.post_images,
      contentCaptions: responseJson.data.attributes.captions,
      contentDetails: responseJson.data,
      isRegeneratingCaption: false,
      postSchedule: scheduleTime,
      meridiem: amPm,
      postStatus: this.handleStatus(responseJson.data.attributes.status)
    })
  }

  handleSaveTODraftResponse(responseJson: { data: ContentDetails }) {
    if (responseJson.data) {
      toast.success("Post successfully saved as a draft.")
      this.props.navigation.goBack()
    } else {
      toast.error('Something went wrong, Please try again later!')
    }
  }
  handleEditManually() {
    this.setState({ isEditContent: true })
  }
  handleChangeCaption(event: ChangeEvent<HTMLInputElement>) {
    const captions = this.state.contentCaptions;
    captions[this.state.activeCaptionIndex].content = event.target.value
    this.setState({ contentCaptions: captions })
  }
  goToNextCaption() {
    if (this.state.activeCaptionIndex < this.state.contentCaptions.length - 1) {
      this.setState({ activeCaptionIndex: this.state.activeCaptionIndex + 1 })
    }
  }
  goToPreviousCaption() {
    if (this.state.activeCaptionIndex > 0) {
      this.setState({ activeCaptionIndex: this.state.activeCaptionIndex - 1 })
    }
  }
  sendRequest = (data: { contentType: string, endPoint: string, method: string, body?: string }) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: this.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  getContentDetails() {
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}`,
      method: configJSON.getMethod,
    }
    this.getContentApiCallId = this.sendRequest(requestData)
  }
  regenerateCaption() {
    this.setState({ isRegeneratingCaption: true })
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}/regenerate_captions`,
      method: configJSON.postMethod,
    }
    this.regenerateCaptionApiCallId = this.sendRequest(requestData)
  }

  saveManuallyWrittenCaption() {
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}/captions/${this.state.contentCaptions[this.state.activeCaptionIndex].id}`,
      method: configJSON.putMethod,
      body: JSON.stringify({ caption: { content: this.state.contentCaptions[this.state.activeCaptionIndex].content } })
    }
    this.saveCaptionApiCallId = this.sendRequest(requestData)
  }
  handleRegenerateImageModalOpen = (id: string) => {
    this.setState({ regenerateImageId: id, isOpenRegenerateImageModal: true })
  }
  handleCancelRegenerateImage() {
    this.setState({ isOpenRegenerateImageModal: false, regenerateImageDescription: "" })
  }

  regenerateImage() {
    this.setState({ isRegeneratingImage: true })
    const requestData: { body?: string, contentType: string, endPoint: string, method: string } = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}/regenerate_image/${this.state.regenerateImageId}`,
      method: configJSON.postMethod,
    }
    if (this.state.regenerateImageDescription.trim().length > 0) {
      requestData.body = JSON.stringify({
        post: {
          new_content: this.state.regenerateImageDescription.trim()
        }
      })
    }
    this.handleCancelRegenerateImage()
    this.regenerateImageApiCallId = this.sendRequest(requestData)
  }
  deleteContentImage(imageId: number) {
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}/delete_image/${imageId}`,
      method: configJSON.deleteMethod,
    }
    this.deleteImageApiCallId = this.sendRequest(requestData)
  }

  handlePostContent() {
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `/bx_block_campaigns/publish_post?account_id=${this.userId}&post_id=${this.contentId}&caption_id=${this.state.contentCaptions[this.state.activeCaptionIndex].id}`,
      method: configJSON.postMethod,
    }
    this.postNowApiCallId = this.sendRequest(requestData)
  }

  handleSchedulePost() {
    const endPoint = `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}/save_and_schedule`;
    const header = {
      token: this.authToken,
    };
    const formData = new FormData()
    if (this.state.postSchedule) {
      const utcScheduleDate = new Date(Date.UTC(
        this.state.postSchedule.getFullYear(),
        this.state.postSchedule.getMonth(),
        this.state.postSchedule.getDate(),
        this.state.postSchedule.getHours(),
        this.state.postSchedule.getMinutes(),
        0
      ));
      const enteredUTCTimestamp = utcScheduleDate.getTime();
      const currentUTCTimestamp = new Date().getTime();
      if (enteredUTCTimestamp > currentUTCTimestamp) {
        formData.append("schedule_date", this.state.postSchedule.toISOString())
        formData.append("caption_id", this.state.contentCaptions[this.state.activeCaptionIndex].id.toString())
      } else {
        toast.error("Please select a future date and time.")
        return
      }
    } else {
      toast.error("Please select a date and time to schedule your post!")
      return
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.schedulePostApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEditImage() {
    toast.info("Feature is under development and will be released shortly!")
  }

  handleSaveImageToLibrary(id: number) {
    toast.info("Feature is under development and will be released shortly!")
  }

  handleChooseImageFromLibrary(id: number) {
    toast.info("Feature is under development and will be released shortly!")
  }
  handlePostScheduleChange(date: Date) {
    const utcScheduleDate = new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      0
    ));
    this.setState({ postSchedule: utcScheduleDate, meridiem: date.getHours() >= 12 ? "PM" : "AM" })
  }
  saveToDraft() {
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}/save_as_draft`,
      method: configJSON.patchMethod,
      body: JSON.stringify({ caption_id: this.state.contentCaptions[this.state.activeCaptionIndex].id, draft: true })
    }
    this.saveAsDraftApiCallId = this.sendRequest(requestData)
  }

  getTimeFromDate(date: Date) {
    const hours = date.getHours() > 12 ? date.getHours() % 12 : date.getHours()
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  
  handleStatus(status:string){
    if(status==='scheduled'){
      return "Scheduled"
    }else if(status==='published'){
      return "Published"
    }else{
      return "Draft"
    }
  }

  handleRemovePost(){
    toast.info("Feature is under development and will be released shortly!")
  }
}

// Customizable Area End
