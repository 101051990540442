import React from 'react';
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {  heartFill, messageIcon, shareIcon, saveIcon, profileIcon, noImage } from '../assets';
import { useState } from 'react';
import { Image } from '../ContentManagementEditController';

interface Props {
    images: Image[];
    caption:string;
}

const InstagramPreview: React.FC<Props> = ({ images, caption }) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    const handleDotClick = (index: number) => {
        if (!isAnimating && index !== currentIndex) {
            setCurrentIndex(index);
        }
    };

    return (
        <>
            <StyledWrapperContainer>
                <StyledHadder>
                    <img src={profileIcon} alt="" />
                    <Box>
                        <Typography sx={styles.f13b}>Username</Typography>
                        <Typography sx={styles.f12b}>Sponsored</Typography>
                    </Box>
                    <MoreHorizIcon sx={{ marginLeft: 'auto' }}/>
                </StyledHadder>
                <div style={styles.carouselContainer}>
                    <div
                        style={{
                            ...styles.imageContainer,
                            transform: `translateX(-${currentIndex * 100}%)`,
                            transition: 'transform 0.5s ease',
                        }}
                    >
                        {images.length>0 ? images.map((image, index) => (
                            <img key={index} src={image.image_url} alt={`carousel-${index}`} style={styles.image} />
                        )):<img src={noImage} style={styles.image}  />}
                    </div>
                </div>
                <Box sx={styles.IconsContainer}>
                    <img src={heartFill} alt="" />
                    <img src={messageIcon} alt="" />
                    <img src={shareIcon} alt="" />
                    <div style={styles.dotsContainer}>
                        {images.map((_, index) => (
                            <span key={index} data-testid={`dot-${index}`} style={{ ...styles.dot, ...(currentIndex === index ? styles.activeDot : {}) }} onClick={() => handleDotClick(index)} />
                        ))}
                    </div>
                    <img src={saveIcon} alt="" style={{ marginLeft: 'auto' }} />
                </Box>

                <Box sx={styles.captionContainer}>
                    <Typography sx={styles.f13g}>{caption}</Typography>
                </Box>

            </StyledWrapperContainer>
        </>
    );
}

export default InstagramPreview;

const StyledWrapperContainer = styled(Box)({
    borderRadius: "20px",
    border: '1px solid #B3B3B3',
    padding: '12px',
    width: "362px",
   '@media (max-width:408px)': {
    width: "clamp(265px, 85vw, 362px)",
  }
})

const StyledHadder = styled(Box)({
    padding: '12px 16px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center'
})

const styles = {
    f13b: {
        fontSize: '13px',
        color: '#000',
        fontWeight: 600,
        fontFamily: 'Open sans',
        lineHeight: '15.6px'
    },
    f13g: {
        fontSize: '13px',
        color: '#4D4D4D',
        fontWeight: 400,
        lineHeight: '15.23px',
        fontFamily: 'Open sans',
    },

    f12b: {
        fontSize: '12px',
        color: '#000',
        fontWeight: 400,
        fontFamily: 'Open sans',
        lineHeight: '14px'
    },
    carouselContainer: {
        height: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative' as 'relative',
        overflow: 'hidden',
    },
    imageContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    image: {
        minWidth: '100%',
        height: '100%',
        objectFit: 'cover' as 'cover',
        transition: 'transform 0.5s ease',
    },
    buttonLeft: {
        position: 'absolute' as 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        border: 'none',
        padding: '10px',
        cursor: 'pointer',
    },
    buttonRight: {
        position: 'absolute' as 'absolute',
        top: '50%',
        transform: 'translate(-50%, -100%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        border: 'none',
        padding: '10px',
        cursor: 'pointer',
    },
    IconsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        margin: '12px 16px',
    },
    dotsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
        transform: 'translateX(-50%)'
    },
    dot: {
        height: '6px',
        width: '6px',
        borderRadius: '50%',
        margin: '0 2px',
        backgroundColor: '#949494',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    activeDot: {
        backgroundColor: '#079FFC',
        width: '8px',
        height: '8px'
    },
    captionContainer: {
        padding: '16px'
    }
};
