import React from "react";
import { Button } from "@mui/material";
import { useGoogleLogin } from '@react-oauth/google';
import { googleIcon } from '../../blocks/email-account-registration/src/assets';

interface CustomGoogleLogInButtonProps {
    onSuccess: (response:string) => void;
    onError:()=>void;
    name: string
}


const CustomGoogleLogInButton: React.FC<CustomGoogleLogInButtonProps> = ({ name, onSuccess, onError }) => {

    const login = useGoogleLogin({
      onSuccess: tokenResponse => onSuccess(tokenResponse.access_token),
      onError: () => onError()
    });
    
    return  <Button onClick={() => login()} style={name != "Register" ? button : buttonReg}>
                <img src={googleIcon} />
                <span style={buttonText}>{name} with Google</span>
            </Button>
}

export default CustomGoogleLogInButton

const buttonText={
    fontFamily:"Open Sans",
    fontSize:"14px",
    fontWeight:400,
    color:"#000000",
    textTransform: 'capitalize' as 'capitalize',
}

const button = {
    border: '1px solid black',
    borderRadius: '8px',
    width:'100%',
    display:'flex',
    gap:'8px'
}

const buttonReg = {
    border: '1px solid #b3b3b3',
    borderRadius: '8px',
    width:'100%',
    display:'flex',
    gap:'8px',
    height:'46px'
}