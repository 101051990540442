import React from 'react';
// Customizable Area Start
import {
    Box,
    styled,
    Select,
    MenuItem,
    Button,
    TextField,
    Typography,
    FormControl
  } from '@mui/material';
import { btnbackIcon, productIcon } from './assets';
import CampaignAddContentController, { Props , configJSON} from './CampaignAddContentController';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import Loader from '../../../components/src/Loader.web';
// Customizable Area End


export default class CampaignAddContent extends CampaignAddContentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderContentPage = () => {
     const { postContent, contentData } = this.state;
      return (<>
      <BackIconBox>
      <img onClick={this.goToCampaignsResults} src={btnbackIcon} className="back_icon" />
      </BackIconBox>
      <ContentHeading data-testId="backBtnTestID">{configJSON.addContent}</ContentHeading>
      <ContentText>{configJSON.contentText}</ContentText>
      <SelectBox>
      <FormControlBox>
      <Select
        data-testId="postSelectTestID"
        className='content_select'
        labelId="content-user"
        placeholder="Select"
        onChange={this.handlePostContentData}
        IconComponent= {KeyboardArrowDownTwoToneIcon}
        value={postContent}
        displayEmpty
        renderValue={(selected:string) => {
            if (selected === "") {
              return <Box className="place_text">{"Select"}</Box>;
            }
            return selected;
          }}
        MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: "#F0F0F0 !important",
                "& .Mui-selected": {
                    backgroundColor:"#D9D9D9 !important"
                }
              },
            },
        }}
        >
        {contentData.map((item, index) =>
         <MenuItem className="content_Item" key={index} value={item}>{item}</MenuItem>
         )}
      </Select>
      </FormControlBox>
      </SelectBox>
      <NextButton><Button className='next_btn' onClick={this.handleNextPage} data-testId="nextButtonTestID">{configJSON.nextBtn}</Button></NextButton>
      </>)
    }

    renderPostPage = () => {
        const { imageVideoText, imageVideoData , isLoading, contentText} = this.state;
        return (<>
            <Loader loading={isLoading}/>
            <BackIconBox onClick={this.handleBackPage} data-testId="backButtonTestID">
                <img src={btnbackIcon} className="back_icon" />
            </BackIconBox>
            <PostHeadingBox>
             <img src={productIcon} className="post_icon" />
            <Typography className='post_content' >{this.state.postContent}</Typography>
            </PostHeadingBox>
            <PostAboutHeading data-testId="addContentTestID">{configJSON.aboutPostText}</PostAboutHeading>
            <PostTextArea>
                <TextField
                    data-testId="postAboutTestID"
                    className='post_textarea'
                    multiline
                    rows={4}
                    placeholder="Please describe"
                    onChange={this.handleContentTextData}
                    value={contentText}
                />
            </PostTextArea>
            <ContentText>{configJSON.imageVideoText}</ContentText>
            <SelectBox>
            <FormControlBox>
                <Select
                    data-testId="imageVideoTestID"
                    className='content_select'
                    labelId="demo-user"
                    placeholder='Select'
                    value={imageVideoText}
                    onChange={this.handleImageVideoData}
                    IconComponent= {KeyboardArrowDownTwoToneIcon}
                    displayEmpty
                    renderValue={(selected: string) => {
                            if (selected === "") {
                                return <Box className="place_text">{"Select"}</Box>;
                            }
                            return selected;
                        }}
                    MenuProps={{
                            PaperProps: {
                                sx: {
                                    backgroundColor: "#F0F0F0 !important",
                                    "& .Mui-selected": {
                                        backgroundColor:"#D9D9D9 !important"
                                    }
                                },
                            },
                        }}
                >
                    {imageVideoData.map((item, index) =>
                        <MenuItem className="content_Item" key={index} value={item}>{item}</MenuItem>
                    )}
                </Select>
                </FormControlBox>
            </SelectBox>
            <NextPostButton><Button className='next_btn' disabled={isLoading} onClick={this.handleNextAddContent} data-testId="createContentTestID">{configJSON.nextBtn}</Button></NextPostButton>
        </>)
    }

    renderAddContentPage = () => {
        return(
        <MainContainer >
        {this.state.nextPage ?  this.renderPostPage() : this.renderContentPage()}
        </MainContainer>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
        <>
           {this.renderAddContentPage()}
        </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainContainer = styled(Box)({
    padding: "80px 100px",
    "@media (max-width: 1000px)": {
        padding: "60px 30px"
    },
    "@media (max-width: 600px)": {
        padding: "50px 20px"
    },
  })

const BackIconBox = styled(Box)({
    cursor: "pointer",
    "& .back_icon": {
      hieght: "18px",
    }
 })

const ContentHeading = styled(Box)({
    marginTop : "24px",
    fontFamily: 'Open Sans',
    fontSize: '24px',
    fontWeight: 600,
    color: '#4D4D4D',
 })

const ContentText = styled(Box)({
    marginTop: "32px",
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 400,
    color: '#4D4D4D'
 })

const SelectBox = styled(Box)({
    marginTop: "40px",
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    color: '#4D4D4D',
    "& .content_select" : {
     width : "100%",
     height: "50px",
     borderRadius: "8px",
     border: "1px solid #DDDDDDDD",
     "& .Mui-focused": {
        "& fieldset": {
            border: "1px solid #DDDDDD !important",
            "&:hover":{
                border: "1px solid #DDDDDD !important"
            }
        }
      },
      "& fieldset": {
        border: "1px solid #DDDDDD !important"
    },
     "&:hover":{
        border : "none"
     },
     "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":{
        backgroundColor: "#F9F9F9 !important",
        color:"#4D4D4D !important"
     },
     "&.css-1d3z3hw-MuiOutlinedInput-notchedOutline" : {
        borderStyle : "none"
     },
     "& .css-6hp17o-MuiList-root-MuiMenu-list":{
        backgroundColor: "#F9F9F9 !important"
     },
     "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected":{
        backgroundColor: "#D9D9D9 !imortant"
     },
     "& .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
         border: "none !imported"
     },
     "& .MuiSelect-icon":{
        color: "#000000"
     },
    "& .content_Item" : {
        "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root" : {
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 400,
            color: '#4D4D4D',
         },
         "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected":{
            backgroundColor: "#D9D9D9 !imortant"
         }
    },
    "& .place_text": {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#B3B3B3',
    }
  }
 })

const FormControlBox = styled(FormControl)({
       width: "100%"
});

const NextButton = styled(Box)({
    marginTop: "200px",
    width: "100%",
    textAlign: "end",
    cursor: "pointer",
    "& .next_btn": {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    color: '#FFFFFF',
    backgroundColor: "#000000",
    padding: "10px 0px",
    width: "210px",
    textTransform:"capitalize",
    borderRadius: "8px",
    "&:hover": {
        backgroundColor: "#000000 !important",
      }
   }
 })

 const NextPostButton = styled(Box)({
    marginTop: "128px",
    width: "100%",
    textAlign: "end",
    cursor: "pointer",
    "& .next_btn": {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    color: '#FFFFFF',
    backgroundColor: "#000000",
    padding: "10px 0px",
    width: "210px",
    textTransform:"capitalize",
    borderRadius: "8px",
    "&:hover": {
        backgroundColor: "#000000 !important",
      }
   }
 })

const PostAboutHeading = styled(Box)({
    marginTop : "24px",
    fontFamily: 'Open Sans',
    fontSize: '24px',
    fontWeight: 600,
    color: '#4D4D4D',
 })

const PostTextArea = styled(Box)({
    marginTop : "32px",
    "& .post_textarea": {
      width : "100%",
      borderRadius: "8px",
      "& .Mui-focused": {
        "& fieldset": {
            border: "1px solid #DDDDDD !important",
            "&:hover":{
                border: "1px solid #DDDDDD !important"
            }
        }
      },
      "& fieldset": {
        border: "1px solid #DDDDDD !important"
    },
      "& .MuiInputBase-root" : {
        color:"#4D4D4D !important",
        fontFamily: 'Open Sans !important',
        fontSize: '14px !important',
        fontWeight: 400,
        border: "1px solid #DDDDDD",
        outline: "none",
        "& .MuiOutlinedInput-notchedOutline":{
           outline: "none",
        "&:hover": {
                borderColor: "#DDDDDD !important"
              },
        }
      }
    }
})

const PostHeadingBox = styled(Box)({
    width : "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "28px",
    gap: "8px",
    padding: "4px 12px",
    backgroundColor: "#FFFDD8",
    borderRadius: "100px",
    border: "0.5px solid #DDDDDD",
    "& .post_icon" : {
        height : "20px"
    },
    "& .post_content": {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: '#4D4D4D',
    }
 })

// Customizable Area End 